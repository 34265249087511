// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-details_yj0Bw{padding:16px}.notification-details__time_szvSX{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:16px;color:var(--TextPrimary)}.notification-details__title_HzGWR{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin-bottom:8px;color:var(--TextDefault)}.notification-details__message_IftwF{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:20px;color:var(--TextPrimary)}.notification-details__message-text_DWjlf{margin-bottom:16px}html.app__layout--desktop .notification-details_yj0Bw{padding:16px 32px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification-details": `notification-details_yj0Bw`,
	"notification-details__time": `notification-details__time_szvSX`,
	"notification-details__title": `notification-details__title_HzGWR`,
	"notification-details__message": `notification-details__message_IftwF`,
	"notification-details__message-text": `notification-details__message-text_DWjlf`
};
export default ___CSS_LOADER_EXPORT___;
