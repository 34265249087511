// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-notifications-top-bar__button_PeSbe{position:absolute;right:0;margin:0 10px}html.app__layout--desktop .customer-notifications-top-bar__button_PeSbe{right:auto;left:0;margin-left:24px}.customer-notifications-top-bar__dropdown-list_OLI9m{display:flex;flex-direction:column;padding:2px;pointer-events:none;background-color:var(--Layer1);border-radius:5px;box-shadow:0 8px 16px var(--ModalShadow)}.customer-notifications-top-bar__dropdown-list-item_p6biH{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;display:flex;align-items:center;padding:14px 12px;color:var(--TextDefault);pointer-events:auto;cursor:pointer}.customer-notifications-top-bar__dropdown-list-item_p6biH:last-child{color:var(--ErrorText)}@media(hover:hover)and (pointer:fine){.customer-notifications-top-bar__dropdown-list-item_p6biH:hover{background-color:var(--Highlight);border-radius:3px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customer-notifications-top-bar__button": `customer-notifications-top-bar__button_PeSbe`,
	"customer-notifications-top-bar__dropdown-list": `customer-notifications-top-bar__dropdown-list_OLI9m`,
	"customer-notifications-top-bar__dropdown-list-item": `customer-notifications-top-bar__dropdown-list-item_p6biH`
};
export default ___CSS_LOADER_EXPORT___;
