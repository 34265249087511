// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .customer-notifications_n5J0n{padding:0 24px 24px}html.app__layout--desktop .customer-notifications__empty_ziFNV{padding:16px 8px 8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customer-notifications": `customer-notifications_n5J0n`,
	"customer-notifications__empty": `customer-notifications__empty_ziFNV`
};
export default ___CSS_LOADER_EXPORT___;
